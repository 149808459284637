.loginWrapper {
  display: grid;
  grid-template-columns: 1fr 1fr;
  height: 100vh;
}

/* left container */
.leftInfo {
  display: flex;
  align-items: center;
  flex-direction: column;
  justify-content: center;
}
.formWrapper {
  width: 400px;
  height: auto;
}
.TitleInfo {
  display: grid;
  place-items: center;
  gap: 10px;
  margin-bottom: 50px;
}
.TitleInfo p:nth-of-type(1) {
  font-size: 25px;
}
.TitleInfo p:nth-of-type(2) {
  font-size: 19px;
  color: #5b5b5b;
}
.signupInfo {
  color: #3d3d3d;
}
.signupLink {
  color: var(--primary-blue);
  cursor: pointer;
}

/* right container */
.rightInfo img {
  width: 100%;
  height: 100%;
  object-fit: cover;
}

@media (max-width: 768px) {
  .loginWrapper {
    grid-template-columns: 1fr;
  }

  .rightInfo {
    display: none;
  }
}

@media (max-width: 460px) {
  .formWrapper {
    width: 90%;
  }
}
