.createBlogContainer {
  margin-top: 100px;
  display: flex;
  flex-direction: column;
  gap: 45px;
  align-items: center;
}
.title {
  font-size: 25px;
  color: var(--primary-blue);
  text-align: center;
  font-weight: 400;
}
.formWrapper {
  width: 500px;
}
.loaderWrapper {
  display: flex;
  justify-content: center;
  align-items: center;
}

@media (max-width: 460px) {
  .formWrapper {
    width: 90%;
  }
}
